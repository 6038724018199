/* global styles  */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;0,900;1,400&display=swap');

@font-face {
    font-family: GraublauWeb;
    src: url("./GlobalAssets/GarmentDistrict-Regular.otf") format("opentype");
}
@import "src/_colors";

body {
    background-color: $tan;
}

.container {
    max-width: 500px;
    margin: 0 auto;
    min-height: 50vh;
    padding-left: 50px;
    padding-right: 50px;
}

.add-post-button {
    width: 200px;
    height: 50px;
    text-align: center;
    display: block;
    margin: 20px auto;
    font-size: 40px;
    text-decoration: none;
    color: white;
    background-color: #1398d5;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

.input-error {
    border-bottom: solid 2px #e26332!important;
    box-sizing: border-box;
}

.hidden {
    display: none;
}

.center-text {
    text-align: center;
}