@import "src/_colors";

.admin-menu {
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;

    &__link-cont {
        width: 100%;
    }
    &__link {
        display: block;
        font-size: 20px;
        margin: 10px 0;
        color: $offWhite;
        background-color: $navy;
        padding: 10px;
        border-radius: 5px;
    }

    &__icon {
        float: right;
        position: relative;
        right: 5px;
    }
}

.approve-invites {
    font-family: 'Roboto', sans-serif;
    color: $rust;
    &__table-hdr {
        font-weight: bold;
    }

    &__row {
        display: flex;
        margin: 20px 0;
        padding: 0 10px;
        span:first-child {
            width: 37%;
        }
        span:nth-child(2) {
            width: 33%;
        }
        span:nth-child(3) {
            margin-left: auto;
        }
    }

    &__approve-btn {
        color: $tan;
        background-color: $rust;
        padding: 8px;
        border-radius: 50%;
        position: relative;
        bottom: 6px;
    }

    &__no-pending {
        text-align: center;
        font-size: 20px;
    }
}

.admin-users {
    font-family: 'Roboto', sans-serif;
    color: $rust;
    &__user-row {
        border: 2px solid $slate;
        border-radius: 5px;
        padding: 10px;
        margin: 10px 0;
    }

    &__user-row.wp_pass {
        border: 2px solid red;
    }

    &__user-name {
        margin: 0;
        padding: 0;
        font-size: 20px;
        text-transform: capitalize;
        color: $slate;
    }

    &__user-info {
        display: flex;

    }

    &__user-email {
        width: 70%;
        color: $slate;
    }

    &__user-edit {
        margin-left: auto;
        color: $slate;
    }

    &__edit-input {
        width: 90%;
        padding: 10px 20px;
        font-size: 20px;
        margin: 0px auto 20px;
        box-sizing: border-box;
        display: block;
        border: none;
        border-bottom: 1px solid $slate;
        background-color: transparent;
        color: $slate;
    }

    &__edit-update {
        width: 90%;
        margin: 25px auto 0;
        display: block;
        padding: 15px;
        font-size: 20px;
        background-color: $rust;	
        color: $tan;
        font-weight: bold;
    }

    &__edit-checkbox {
        -webkit-appearance:none;
        width:20px;
        height:20px;
        background:white;
        border-radius:5px;
        border:2px solid #555;
        box-sizing: border-box;
        margin: 0 5px 0 0;
    }

    &__edit-checkbox:checked {
        background-color: $rust;
        border: 2px solid $rust;
    }
}

.feed__link {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    height: 200px;
    opacity: 0;
}

.edit-post {
    padding: 0;
    font-family: 'Roboto', sans-serif;
    &__row {
        display: flex;
        align-items: center;
        margin: 0 0 15px;   
    }
    &__image {
        height: 50px;
        width: 50px;
        object-fit: contain;
    }
    &__caption {
        width: 50%;
        margin-left: auto;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        line-height: 1;
        color: $slate;
    }
    &__actions-cont {
        margin-left: auto;
        font-size: 20px;
        padding-right: 10px;
        .fa-square-pen {
            margin-right: 10px;
            font-size: 30px;
            color: $slate;
        }

        .fa-circle-xmark{
            color: red;
            background-color: #fff;
            border-radius: 50%;
            font-size: 30px;
        }
    }

    &__row--delete {
        display: block;
        div {
          background-color: $tan;
        }
    }

    &__image--delete {
        height: 200px;
        margin: 0 auto;
        display: block;
    }

    &__image-edit {
        width: auto;
    }

    &__caption--delete {
        margin: 10px auto;
        text-align: center;
        width: 75%;
    }

    &__delete-container {
        color: $offWhite;
        padding: 10px;
        border-radius: 5px;
        margin: 0 0 15px;
    }

    &__delete-header {
        font-size: 18px;
        text-align: center;
        margin: 0;
    }

    &__edit-header {
        font-size: 24px;
        text-align: center;
    }

    &__delete-actions {
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }

    &__btn {
      background-color: $darkSlate;
      width: 25%;
      color: #fff;
      text-align: center;
      text-decoration: underline;
    }

    &__btn--edit {
      text-decoration: none;
      padding: 10px;
      outline: none;
      outline-style: none;
      background: none repeat scroll 0 0 transparent;
      border: medium none;
      border-spacing: 0;
      background-color: #292929;
      min-width: 55%;
      margin: 0 5px;
    }

    &__textarea {
        margin: 15px 0;
    }

    hr {
        background-color: $slate;
        border: none;
        height: 1px;
    }
}