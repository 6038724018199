@import "src/_colors";

.add-post {
    font-family: 'Roboto', sans-serif;
    &__input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    
    &__input-label {
        width: 100%;
        margin: 25px auto;
        display: block;
        padding: 15px;
        font-size: 20px;
        background-color: $rust;	
        color: $tan;
        font-weight: bold;
        text-align: center;
        box-sizing: border-box;
    }
    
    &__target {
        margin: 10px auto;
        display: block;
        border: 2px $slate solid;
        width: 100%;
        box-sizing: border-box;
    }
    
    &__caption {
        height: auto;
        width: 100%;
        border-radius: 0;
        outline: none;
        padding: 0 15px;
        box-sizing: border-box;
    }
    
    &__caption::placeholder {
        text-align: center;
        font-size: 20px;
    }
    
    &__submit {
        width: 100%;
        margin: 25px auto;
        display: block;
        padding: 15px;
        font-size: 20px;
        background-color: $rust;	
        color: $tan;
        font-weight: bold;
        text-align: center;
        box-sizing: border-box;
    }
    
    &__success {
        text-align: center;
        font-size: 40px;
        line-height: 1;
        padding: 5px;
        color: $rust;
    }

    &__success-message {
        color: $navy;
    }

    &__header, &__go-back, &__subheader {
        color: $slate;
    }

    &__header {
      margin: 0 0 10px;
    }

    &__subheader {
      font-size: 16px;
    }
}