@import "src/_colors";

.pagination {
    display: flex;
    justify-content: center;
    margin: 0 0 20px;

    &__box {
        border: 1px solid $slate;
        width: 40px;
        height: 40px;
        margin: 0 5px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        color: $slate;
    }

    &__box.active {
        background-color: $rust;
        color: $tan
    }
}