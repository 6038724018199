.post {
    border-radius: 5px;
    border: 1px solid #dbdbdb;
    border: 1px solid rgba(var(--b6a,219,219,219),1);
    margin: 0 0 20px;
    background-color: #fff;
    width: 100%;
    overflow: hidden;

    &__image {
        max-width: 500px;
        width: 100%;
    }

    &__content-cont {
        padding: 10px;
        position: relative;
        padding-bottom: 45px;
    }

    &__content, &__date {
        margin: 0;
        font-family: 'Roboto', sans-serif;
    }

    &__content {
        margin: 5px 0 10px;
        font-weight: 300;
        line-height: 1.2;
    }

    &__flex-cont {
      display: flex;
      align-items: center;
      height: 97%;
    }

    &__rotate-btn-cont {
      display: flex;
      justify-content: space-evenly;
      margin: 0 auto 20px;
      background-color: #e2d8cd;
      padding: 0px 10px 10px;
    }

    hr {
        border-top: grey 1px;
    }
}

.thumbs-wrapper {
  display: none;
}

.carousel-status {
  font-family: 'Montserrat', sans-serif;
  color: #000!important;
  text-shadow: none!important;
}

.carousel .slide {
  background-color: #fff!important;
}
.carousel .control-dots {
  bottom: 10px !important;
}

.carousel .control-dots .dot {
  background-color: #000;
}