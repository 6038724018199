@import "src/_colors.scss";

.invite {
    font-family: 'Roboto', sans-serif;
    margin: 50px auto;

    &__header {
        font-size: 40px;
        text-align: center;
        margin: 0 0 60px;
    }
    
    &__label {
        display: block;
        text-align: left;
        width: 90%;
        margin: 0 auto;
        font-size: 16px;
        color: $slate;
    }
    
    &__input {
        width: 90%;
        padding: 10px 20px;
        font-size: 20px;
        margin: 0px auto 20px;
        box-sizing: border-box;
        display: block;
        border: none;
        border-bottom: 1px solid ;
        background-color: transparent;
        color: $slate;
        border-radius: 0;
    }

    &__input:focus {
        outline: none;
    }
    
    &__submit {
        width: 90%;
        margin: 25px auto 0;
        display: block;
        padding: 15px;
        font-size: 20px;
        background-color: $rust;	
        color: $tan;
        font-weight: bold;
    }
    
    &__success-header {
        text-align: center;
        font-size: 50px;
        color: $rust;
    }
    
    &__success-subheader {
        text-align: center;
        font-size: 16px;
        color: $slate;
        border-bottom: 1px solid #4e6350;
        padding: 10px 0 20px;
        margin: 16px 19px 50px;
        width: 110%;
        position: relative;
        left: -10%;
    }

    &__error {
        text-align: center;
        color: rgb(249,235,218);
        a {
            font-size: 20px;
        }
    }

    &__login {
        text-align: center;
        color: $rust;
        text-decoration: underline;
        font-weight: 300;
        font-size: 16px;
        font-style: italic;
    }
}