@import "src/_colors.scss";

.reset-password {
    margin: 0 auto;
    &__header {
        color: $rust;
        font-size: 36px;
        font-weight: bold;
    }

    &__label {
        display: block;
        text-align: left;
        width: 90%;
        margin: 0 auto;
        font-size: 16px;
        color: $rust;
    }

    &__success {
        text-align: center;
        font-size: 18px;
        color: $rust;
    }
}