@import "src/_colors";

.loading {
    height: 100vh;
    position: absolute;
    width: 100vw;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    top: 0;
    left: 0;
    background-color: $tan;
    z-index: 10;
    &__text {
        text-align: center;
        font-size: 40px;
        font-family: 'GraublauWeb', sans-serif;
        margin: 80px 0;
    }

    &__icon-cont {
        width: 100%;
        height: 40vh;
    }

    &__icon {
        width: 60vw;
        margin: 20% auto 0;
        animation: load 3s;
        animation-iteration-count: 1;
        max-height: 500px;
        background-position: top;
        background-size: 200%;
        background-repeat: no-repeat;
        max-width: 250px;
        height: 500px;
    }

    &__loading_bar-cont {
        height: 40px;
        width: 75%;
        margin: 0 auto;
        position: relative;
    }
    
    &__loading_bar {
        width: 50%;
        height: 100%;
        background-color: $navy;
    }

    &__loaded_percent {
        line-height: 2;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        font-size: 21px;
        text-shadow: 1px 0 0 $tan, -1px 0 0 $tan, 0 1px 0 $tan, 0 -1px 0 $tan, 1px 1px $tan, -1px -1px 0 $tan, 1px -1px 0 $tan, -1px 1px 0 $tan;
    } 
}

@keyframes load {
    0% {
      height: 0vw;
    }
  
    100% {
        height: 100vw;
    }
  }