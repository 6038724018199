@import "src/_colors";

.login {
    font-family: 'Roboto', sans-serif;
    margin-top: 50px;
    &__header {
        font-size: 40px;
        text-align: center;
        margin: 0 0 60px;
    }
    
    &__label {
        display: block;
        text-align: left;
        width: 90%;
        margin: 0 auto;
        font-size: 16px;
        color: black;
        color: $slate;
    }
    
    &__input {
        width: 90%;
        padding: 10px 20px;
        font-size: 20px;
        margin: 0px auto 20px;
        box-sizing: border-box;
        display: block;
        border: none;
        border-bottom: 1px solid $slate;
        background-color: transparent;
        color: $slate;
        border-radius: 0;
    }

    &__input:focus {
        outline: none;
    }
    
    &__submit {
        width: 90%;
        margin: 25px auto 0;
        display: block;
        padding: 15px;
        font-size: 20px;
        background-color: $rust;	
        color: $tan;
        font-weight: bold;
    }
    
    &__success-header {
        text-align: center;
        font-size: 50px;
    }
    
    &__success-subheader {
        text-align: center;
        padding: 10px;
        font-size: 20px;
    }

    &__error {
        text-align: center;
        color: #e26332;
        font-size: 20px;
    }

    &__forgot-password {
        margin: 100px 0 0;
        color: $rust;
        font-style: italic;
    }

    &__request-text {
        margin: 25px 0 0;
        line-height: 1;
    }

    &__request-access {
        color: $rust;
        font-style: italic;
        margin: 0;
    }
}

@media screen and (max-width: 375px) {
    .login {
        margin-top: 20px;
        &__forgot-password {
            margin: 50px 0 0;
        }
    }
}