@import "src/_colors.scss";
.feed-header {
    display: block;
    padding: 20px 0 40px;
    justify-content: space-around;
    height: auto;
    min-height: initial;
    &__logo {
        max-width: 300px;
        margin: -100px auto;
        display: block;
    }
    &__admin-btn {
        display: block;
        margin: 0 auto;
        font-size: 30px;
        color: $navy;
    }
}