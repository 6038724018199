.feed {
    &__go-back {
        font-family: 'Roboto', sans-serif;
        cursor: pointer;
    }

    &__admin-button {
        margin: 0 auto 20px;
        display: block;
        width: 80px;
        border-radius: 50%;
    }

    &__admin-link {
        font-family: 'Roboto', sans-serif;
        text-align: right;
        width: 100%;
        display: block;
        margin: 0 0 20px;
        font-size: 20px;
        color: rgb(78, 99, 80);
    }
}

.feed.container {
    padding: 10px;
}