@import "src/_colors";

.forgot-password {
    margin: 40% auto;
    &__message {
        width: 88%;
        margin: 0 auto 30px;
        text-align: center;
        color: $slate;
    }

    &__success-header {
        text-align: center;
        font-size: 50px;
        color: $rust;
    }
    
    &__success-subheader {
        text-align: center;
        font-size: 16px;
        color: $slate;
        border-bottom: 1px solid #4e6350;
        padding: 10px 0 20px;
        margin: 16px 19px 50px;
        width: 110%;
        position: relative;
        left: -10%;
    }

    &__message--success {
        padding: 60px 0 10px;
        box-sizing: border-box;
        border-bottom: 1px solid $slate;
    }

    &__label {
        display: block;
        text-align: left;
        width: 90%;
        margin: 0 auto;
        font-size: 16px;
        color: $slate;
    }

    &__header {
        color: $rust;
        font-size: 36px;
        font-weight: bold;
        width: 120%;
        position: relative;
        left: -10%;
    }
}